<template>
  <div class="px-10">
    <div class="text-h4 primary--text mb-5">
      {{ isUpdate ? 'แก้ไข' : 'สร้าง' }}หมวดหมู่ย่อยสินค้าขนส่ง
    </div>
    <v-row>
      <!-- <v-col cols="12" class="pl-10">
            <div class="text-h5 primary--text">ข้อมูลสินค้า</div>
        </v-col> -->
      <v-col cols="4" class="pl-15">
        <v-text-field
          v-model="form.name"
          :error-messages="$v.form.name.$error ? $error_text : ''"
          label="ชื่อหมวดหมู่ย่อย (สินค้าขนส่ง)"
          outlined
          dense
        />
        <v-select
          v-model="form.branch_id"
          :error-messages="$v.form.branch_id.$error ? $error_text : ''"
          :items="list.branchs"
          item-value="_id"
          item-text="branch_name"
          label="สาขา (เลือกเพียง 1 สาขาเท่านั้น)"
          dense
          outlined
          multiple
        ></v-select>
        <v-text-field
          v-model="form.merging_name"
          label="ชื่อสำหรับรวมสินค้า"
          outlined
          dense
        />
        <v-textarea v-model="form.note" label="หมายเหตุ" outlined dense />
      </v-col>
      <v-col offset="1">
        <span class="mr-5">สถานะ</span>

        <menu-ship
          v-model="form.is_available"
          :disabled="!isUpdate"
          path="sub_category/changed_sub_category_status"
          :params="{ sub_category_id: form._id }"
          @success="
            val => {
              $alertSuccess(), (form.is_available = val);
            }
          "
        />
      </v-col>
    </v-row>

    <v-toolbar dense flat class="mb-5">
      <div class="primary--text text-h5">ตารางสินค้า</div>
      <v-spacer></v-spacer>
      <div style="width: 400px">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหาชื่อสินค้า"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup="getProducts"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <!-- <v-btn color="success" outlined small>เปิดใช้งาน</v-btn>
      <v-btn color="error" outlined small class="ml-2">ปิดใช้งาน</v-btn> -->
      <v-btn
        color="success"
        outlined
        small
        class="ml-5"
        :disabled="!selected.length"
        @click="changedStatus(true)"
      >
        เปิดใช้งาน
      </v-btn>
      <v-btn
        color="error"
        outlined
        small
        class="ml-2"
        :disabled="!selected.length"
        @click="changedStatus(false)"
        >ปิดใช้งาน</v-btn
      >
      <v-btn
        color="primary"
        outlined
        small
        class="ml-5"
        @click="(product_id = 0), (dialogUpdate = true)"
        :disabled="!isUpdate"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มสินค้า</v-btn
      >
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list.products"
      :search="search"
      :loading="loading"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      show-select
      item-key="_id"
      v-model="selected"
      @click:row="edit"
    >
      <template v-slot:[`item.is_available`]="{ item }">
        <menu-ship
          v-model="item.is_available"
          path="product/changed_product_status"
          :params="{ product_id: item._id }"
          @success="$alertSuccess(), getProducts()"
        />
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon @click="editItem(item)">mdi-dots-horizontal</v-icon> -->

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item
                dense
                link
                @click="(product_id = item._id), (dialogUpdate = true)"
              >
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ปิด</v-btn
        >
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
          >บันทึกข้อมูล</v-btn
        >
      </v-col>
    </v-row>

    <!-- <v-dialog scrollable max-width="1000" v-model="dialogUpdate">
      <Update @onClose="dialogUpdate=false"/>
    </v-dialog> -->

    <v-dialog persistent scrollable max-width="1200" v-model="dialogUpdate">
      <ProductDetail
        :id="product_id"
        @onClose="dialogUpdate = false"
        :main_category="{ _id: form.category_id, name: category_name }"
        :sub_category="{ _id: form._id, name: form.name }"
        @success="getProducts"
        :dialogUpdate="dialogUpdate"
      />
    </v-dialog>
  </div>
</template>

<script>
import ProductDetail from '@/views/pages/product/transport/ProductDetail.vue';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    ProductDetail
  },
  validations: {
    form: {
      name: { required },
      branch_id: { required }
    }
  },
  data: () => ({
    product_id: 0,
    form: {
      category_id: 'PC3',
      name: '',
      branch_id: [],
      merging_name: '',
      note: '',
      is_available: true
    },

    search: '',
    selected: [],
    loading: false,
    pageCount: 0,
    filter: {
      item_per_page: 20,
      page: 1,
      start_with: '',
      branch_id: 0,
      sub_category_id: 0
    },
    headers: [
      // {
      //   text: 'รหัสสินค้า',
      //   align: 'start',
      //   sortable: false,
      //   value: 'product_code',
      //   filterable: false
      // },
      { text: 'ชื่อสินค้า', value: 'name' },
      { text: 'รายละเอียด', value: 'detail', filterable: false },
      { text: 'ราคา', value: 'price', filterable: false },
      { text: 'สถานะ', value: 'is_available', filterable: false },
      { text: '', value: 'actions', sortable: false, filterable: false }
    ],

    dialogUpdate: false,
    list: {
      branchs: [],
      products: [],
      main_categories: [
        { category_id: 'PC1', name: 'พรบ.' },
        { category_id: 'PC2', name: 'ประกันภัย' },
        { category_id: 'PC3', name: 'ขนส่ง' },
        { category_id: 'PC4', name: 'ทั่วไป' }
      ]
    }
  }),

  async created() {
    this.getBranchs();
    if (this.isUpdate) {
      await this.getData();
      await this.getProducts();
    }
  },

  methods: {
    edit(item) {
      this.product_id = item._id
      this.dialogUpdate = true
    },
    changedStatus(status) {
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let product_id = [];
            this.selected.forEach(element => {
              product_id.push(element._id);
            });

            let fill = {
              product_id: product_id,
              is_available: status
            };
            console.log(fill);
            let body = {
              token: this.$jwt.sign(fill, this.$privateKey, {
                noTimestamp: true
              })
            };
            await this.$axios
              .post(`${this.$baseUrl}/product/changed_product_status`, body)
              .then(res => {
                this.selected = [];
                this.$alertSuccess({ title: res.status });
                this.getData(this.id);
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.getProducts();
        }
      );
    },
    getProducts() {
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      this.$axios
        .post(`${this.$baseUrl}/product/get_general_product`, body)
        .then(async res => {
          this.list.products = res.result.data;
          this.pageCount = res.result.total_page || 1;
        });
    },
    getBranchs() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true })
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_branch_list`, body)
        .then(async res => {
          this.list.branchs = res.result;
        });
    },
    async getData() {
      let body = {
        token: this.$jwt.sign(
          { sub_category_id: this.$route.params.id },
          this.$privateKey,
          { noTimestamp: true }
        )
      };
      await this.$axios
        .post(`${this.$baseUrl}/sub_category/get_by_id`, body)
        .then(async res => {
          this.form = { ...res.result };
          this.filter.sub_category_id = this.form._id;
        })
        .catch(err => {
          console.log('err', err);
        });
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let subPath = 'add_sub_category';
            if (this.isUpdate) {
              subPath = 'edit_sub_category';
              this.form = { sub_category_id: this.form._id, ...this.form };
              delete this.form._id;
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true
              })
            };
            await this.$axios
              .post(`${this.$baseUrl}/sub_category/${subPath}`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                if (!this.isUpdate)
                  this.$router.push({ name: 'product-transport' });
              })
              .catch(err => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
          this.$v.form.$reset();
        }
      );
    },
    close() {
      this.$router.push({ name: 'product-transport' });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { product_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              )
            };

            await this.$axios
              .post(`${this.$baseUrl}/product/delete_product`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.getProducts();
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getProducts();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    }
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
    category_name() {
      let filtered = this.list.main_categories.filter(el => {
        return el.category_id == this.form.category_id;
      });
      return filtered.length ? filtered[0].name : '';
    }
  }
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
